import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AutoSelect from './AutoSelect'
import { useEffect, useState } from 'react';

function Division(props) {
  const [divisionSelectionOptions, setDivisionSelectionOptions] = useState([])
  const {
    competition,
    divisionSelection,
    setDivisionSelection,
    setStep,
    setAlertParams,
    setDisplaySuccess
  } = props

  useEffect(() => {
    const divisionSelectionOptions = competition.dth === "No" ? ['Setter', 'Closer'] : ['Rookie', 'Veteran']

    setDivisionSelectionOptions(divisionSelectionOptions)
  })

  return (
    <div className='centered-form'>
      <h2>{competition?.label}</h2>
      <AutoSelect value={divisionSelection} setValue={setDivisionSelection} options={divisionSelectionOptions} placeholder={"Division"} multi={false} setDisplaySuccess={setDisplaySuccess}/>
      <Stack spacing={2} direction="row">
        <Button className='jbutton' variant="contained" onClick={e => {
          if(divisionSelection) {
            setStep(2)
          } else {
            setAlertParams(['You need to select a division'], 'Validation Error', true)
          }
        }}>Submit</Button>
      </Stack>
    </div>
  );
}

export default Division;