import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

function BreadC(props) {
  const {
    setStep,
    competition,
    setAlertParams,
    divisionSelection
  } = props

  const navToTeamMembers = () => {
    if(competition && competition.divisionSplit === 'No') {
      setStep(2)
    } else if(competition && competition.divisionSplit === 'Yes' && !divisionSelection) {
      const message = ['You need to select a division.']

      setAlertParams(message, 'Validation Error', true)
    } else if (!competition) {
      const message = ['You need to select a competition.']

      setAlertParams(message, 'Validation Error', true)
    }
  }
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" style={{color: 'white'}}>
        <Button underline="hover" color="inherit" onClick={() => setStep(0)}>
          Competition
        </Button>
        { competition && competition.divisionSplit === "Yes" ?
          <Button
            underline="hover"
            color="inherit"
            onClick={() => setStep(1)}
          >
            Division
          </Button>
        : null }
        <Button underline="hover" color="inherit" onClick={() => navToTeamMembers()}>
          Team Members
        </Button>
      </Breadcrumbs>
    </div>
  );
}

export default BreadC;