import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ControllableStates(props) {
  const { options, multi, value, setValue, placeholder, searchMethod, setDisplaySuccess } = props
  const [inputValue, setInputValue] = React.useState('');

  return (
    <div>
      <Autocomplete
        className='mu-input'
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setDisplaySuccess(false)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if(searchMethod) {
            searchMethod(newInputValue)
          }
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        sx={{ width: 300 }}
        multiple={multi}
        renderInput={(params) => <TextField {...params} label={placeholder} />}
      />
    </div>
  );
}