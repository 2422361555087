import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';

function Auth(props) {
  const {
    lastName,
    setLastName,
    userBadgeId,
    setUserBadgeId,
    login
  } = props

  useEffect(() => {
  },[])

  return (
    <div className="App">
      <div className='centered-form'>
        <div style={{width: '80%', fontSize: '12px'}}><p>Please enter your badge ID and last name in order to sign in and register a team for competition.</p></div>
        <div className='labeled'>
          <p>Last Name</p>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            style={{width: '100%'}}
          >
            <TextField value={lastName} id="outlined-basic" variant="outlined" onChange={e => setLastName(e.target.value)} />
          </Box>
        </div> 

        <div className='labeled'>
          <p>Badge ID</p>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            style={{width: '100%'}}
          >
            <TextField value={userBadgeId} id="outlined-basic" variant="outlined" onChange={e => setUserBadgeId(e.target.value)} />
          </Box>
        </div> 

        <Stack spacing={2} direction="row">
          <Button className='jbutton' variant="contained" onClick={e => login()}>Log In</Button>
        </Stack>
      </div>
    </div>
  );
}

export default Auth;